<template>
    <div>
        <div 
            v-if="!vertical" 
            class="filter_pop_wrapper relative"
            :class="tags.length || filtersSearch.length ? 'filter_active' : ''">
            <a-button
                v-if="tags.length || filtersSearch.length"
                @click="removeFilters()"
                type="link"
                class="text_current absolute filter_clear ant-btn-icon-only">
                <i class="fi fi-rr-cross-small"></i>
            </a-button>
            <a-popover
                v-model="visible"
                :trigger="onlySearch ? 'none' : 'click'"
                :autoAdjustOverflow="false"
                :align="align"
                :getPopupContainer="getPopupContainer"
                :overlayStyle="{
                    width: '100%',
                    maxWidth: filterPopupWidth,
                    zIndex: zIndex,
                }"
                :placement="placement"
                class="filter_popover"
                :class="size">
                <div 
                    v-if="mode === 'tag'" 
                    class="filter_input cursor-pointer relative flex items-center"
                    :class="showSearchInput && 'search_input'">
                    <template v-if="tags.length">
                        <a-tag
                            v-for="(filter, index) in tagsViewFilter"
                            :key="filterItemKey(filter, index)"
                            :color="checkTagColor(filter)"
                            class="cursor-pointer">
                            <div class="flex items-center filter_tag">
                                <template v-if="filter.type === 'other'">
                                    +{{filter.value.length}}
                                </template>
                                <template v-else>
                                    <span class="mr-1">{{filter.verbose_name}}:</span>
                                    <template v-if="filter.field === 'date' || filter.field === 'input'">
                                        <div class="value_name">{{filter.value}}</div>
                                    </template>
                                    <template v-else>
                                        <div 
                                            v-if="filter.value[0].full_name"
                                            class="flex items-center">
                                            <div class="mr-1">
                                                <a-avatar 
                                                    v-if="filter.value[0].avatar" 
                                                    :size="14" 
                                                    :src="filter.value[0].avatar.path" />
                                                <a-avatar 
                                                    v-else 
                                                    :size="14" 
                                                    icon="user" />
                                            </div>
                                            <div class="value_name">{{filter.value[0].full_name}}</div>
                                        </div>
                                        <span 
                                            v-else 
                                            class="truncate"
                                            style="max-width: 160px;">
                                            {{filter.value[0].string_view ? filter.value[0].string_view : filter.value[0].name}}
                                        </span>
                                        <span 
                                            v-if="filter.value.length > 1"
                                            class="ml-1">
                                            +{{filter.value.length-1}}
                                        </span>
                                    </template>
                                </template>
                            </div>
                        </a-tag>
                    </template>
                    <template v-else>
                        <template v-if="!showSearchInput" >
                            <div 
                                v-if="!filterLoading"
                                class="flex items-center">
                                <a-tag
                                    class="cursor-pointer"
                                    color="green">
                                    {{$t('show_all')}}
                                </a-tag>
                                <a-divider type="vertical" />
                                <span class="text-gray ml-2 font-extralight">
                                    {{$t('select_filter')}}
                                </span>
                            </div>
                        </template>
                    </template>
                    <template v-if="showSearchInput" >
                        <div 
                            v-if="tags.length && filtersSearch && filtersSearch.length" 
                            class="search_sp">
                            +
                        </div>
                        <div class="main_search w-full">
                            <a-input
                                :value="filtersSearch"
                                class="w-full"
                                :placeholder="inputPlaceholder"
                                @keydown="keydownSearchInput"
                                @change="changeSearchInput">
                                <template slot="suffix">
                                    <a-icon v-if="searchLoading" type="loading" />
                                    <i v-else class="fi fi-rr-search"></i>
                                </template>
                            </a-input>
                        </div>
                    </template>
                    <a-icon 
                        v-if="filterLoading" 
                        class="absolute" 
                        type="loading" />
                </div> 
                <a-button
                    v-else
                    icon="filter"
                    :type="buttonType"
                    :size="size"
                    :loading="filterLoading">
                    {{$t('filters')}}
                </a-button>
                <div slot="content">
                    <div 
                        v-show="filterLoading" 
                        class="flex justify-center p-2">
                        <a-spin />
                    </div>
                    <div v-show="!filterLoading">
                        <div class="filter_header flex items-center justify-between pb-2">
                            <div class="flex items-center">
                                <span class="font-semibold">{{$t('filter')}}</span>
                                <div class="ml-8 flex items-center" v-if="checkExclude">
                                    <a-button
                                        size="small"
                                        class="flex items-center"
                                        @click="selectTab(1)"
                                        :ghost="activeTab === 1 ? false :true"
                                        type="ui">
                                        <i class="fi fi-rr-add mr-1"></i>
                                        {{ $t('f_select') }} {{includeLenght > 0 ? `(${includeLenght})` : ''}}
                                    </a-button>
                                    <a-button
                                        class="ml-1"
                                        icon="minus-circle"
                                        size="small"
                                        @click="selectTab(2)"
                                        :ghost="activeTab === 2 ? false :true"
                                        type="ui">
                                        {{$t('exclude')}} {{excludeLenght > 0 ? `(${excludeLenght})` : ''}}
                                    </a-button>
                                </div>
                            </div>
                            <a-button type="ui" ghost icon="fi-rr-cross" shape="circle" flaticon @click="visible = false" />
                        </div>
                        <div class="filter_body py-4 pr-2">
                            <div 
                                class="grid" 
                                :class="listColumn">
                                <div v-show="activeTab === 1" v-for="f in filterInclude" :key="f.name">
                                    <WidgetsSwicth
                                        :filter="f"
                                        :page_name="page_name"
                                        :injectSelectParams="injectSelectParams"
                                        :filterPrefix="filterPrefix"
                                        :modelLabel="modelLabel"
                                        :windowWidth="windowWidth"
                                        :name="name" />
                                </div>
                                <div v-show="activeTab === 2" v-for="f in filterExclude" :key="f.name">
                                    <WidgetsSwicth
                                        :filter="f"
                                        :page_name="page_name"
                                        :filterPrefix="filterPrefix"
                                        :injectSelectParams="injectSelectParams"
                                        :modelLabel="modelLabel"
                                        :windowWidth="windowWidth"
                                        :name="name" />
                                </div>
                            </div>
                        </div>
                        <div class="filter_footer flex items-center justify-end pt-2">
                            <!--<a-button type="ui" @click="resetFilters()">
                                {{$t('reset')}}
                            </a-button>-->
                            <a-button 
                                class="ml-2"
                                type="ui"
                                @click="removeFilters()">
                                {{$t('reset')}}
                            </a-button>
                            <a-button
                                icon="search"
                                type="primary"
                                class="ml-2 px-8"
                                @click="setFilter()">
                                {{$t('find')}}
                            </a-button>
                        </div>
                    </div>
                </div>
            </a-popover>
        </div>
        <!--/////////////////////////////////////// VERTICAL /////////////////////////////////////// -->
        <div v-else>
            <a-card>
                <div v-show="filterLoading" class="flex justify-center p-2">
                    <a-spin />
                </div>
                <div v-show="!filterLoading">
                    <span class="font-semibold text-base">{{$t('filters')}}</span>
                    <div class="filter_header flex items-center justify-between pb-3 mt-2">
                        <div class="flex items-center">
                            <div class=" flex items-center" v-if="checkExclude">
                                <a-button
                                    size="small"
                                    class="flex items-center"
                                    @click="selectTab(1)"
                                    :type="activeTab === 1 ? 'primary' : 'link'">
                                    <i class="fi fi-rr-add mr-1"></i>
                                    {{$t('f_select')}} {{includeLenght > 0 ? `(${includeLenght})` : ''}}
                                </a-button>
                                <a-button
                                    class="ml-1"
                                    icon="minus-circle"
                                    size="small"
                                    @click="selectTab(2)"
                                    :type="activeTab === 2 ? 'primary' : 'link'">
                                    {{$t('exclude')}} {{excludeLenght > 0 ? `(${excludeLenght})` : ''}}
                                </a-button>
                            </div>
                        </div>
                    
                    </div>
                    <div class="filter_body filter_body__vertical py-1 pr-2">
                        <div class="" :class="listColumn">
                            <div v-show="activeTab === 1" v-for="f in filterInclude" :key="f.name">
                                <WidgetsSwicth
                                    class="mb-3"
                                    :filter="f"
                                    vertical
                                    :injectSelectParams="injectSelectParams"
                                    :page_name="page_name"
                                    :windowWidth="windowWidth"
                                    :name="name" />
                            </div>
                            <div v-show="activeTab === 2" v-for="f in filterExclude" :key="f.name">
                                <WidgetsSwicth
                                    class="mb-3"
                                    :filter="f"
                                    vertical
                                    :injectSelectParams="injectSelectParams"
                                    :page_name="page_name"
                                    :windowWidth="windowWidth"
                                    :name="name" />
                            </div>
                        </div>
                    </div>
                    <div class="filter_footer flex flex-col items-center justify-end pt-4">
                        <a-button
                            icon="search"
                            type="primary"
                            class="w-full"
                            @click="setFilter()">
                            {{$t('find')}}
                        </a-button>
                        <div class="flex justify-between items-center w-full mt-2">
                            <a-button 
                                v-if="!hideResetBtn"
                                class="w-full"
                                @click="resetFilters()">
                                <a-icon type="redo" />  {{$t('reset')}}
                            </a-button>
                            <a-button 
                                v-if="!hideClearBtn"
                                :class="!hideResetBtn && 'ml-2'"
                                :disabled="disabledClearBtn"
                                class="w-full flex items-center justify-center"
                                @click="clearFilters()">
                                <i class="fi fi-rr-trash mr-1"></i>  {{$t('clear')}} 
                            </a-button>
                        </div>
                    </div>
                </div>
            </a-card>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import eventBus from '@/utils/eventBus'
import computedM from './mixins/computed'
import methodsM from './mixins/methods'
import propsM from './mixins/props'
import WidgetsSwicth from './widgets/WidgetsSwicth'
export default {
    components: {
        WidgetsSwicth
    },
    mixins: [
        methodsM,
        computedM,
        propsM
    ],
    data() {
        return {
            visible: false,
            filterLoading: false,
            tags: [],
            first: true,
            input: '',
            name: this.model,
            activeTab: 1,
            filterInclude: [],
            filterExclude: [],
            searchLoading: false,
            filterIncludeData: null
        }
    },
    created() {
        this.filterInit()
    },
    methods: {
        ...mapActions({
            getFiltersByKey: "filter/getFiltersByKey"
        }),
        checkTagColor(filter) {
            if(filter.name)
                return filter.name.includes("_exclude") ? 'purple' : 'blue'
            
            return ''
        },
        filterItemKey(filter, index) {
            switch (filter.field) {
            case 'array':
                return filter.value?.[0]?.id ? filter.value?.[0]?.code ? `${filter.value[0].code}_${filter.name}` : `${filter.value[0].id}_${filter.name}` : `${filter.name}_${filter.field}`
                break;
            default:
                return index
            }
        },
        async filterInit() {
            try {
                this.filterLoading = true
                this.$store.commit('filter/SET_FILTER_LOADING', {
                    name: this.page_name,
                    value: true
                })
                this.name = this.page_name
                if(!this.checkLoaded || this.forceInit){ 
                    await this.getFiltersByKey(
                        {
                            name: this.model,
                            page_name: this.page_name,
                            params:  this.queryParams,
                            excludeFields: this.excludeFields
                        })
                } 
                this.setFilterData()
                await this.tagGenerate()
            } catch(e) {
                console.log(e)
            } finally {
                this.$store.commit('filter/SET_FILTER_LOADING', {
                    name: this.page_name,
                    value: false
                })
                this.filterLoading = false
            }
        }
    },
    mounted() {
        eventBus.$on(`send_include_fields_${this.page_name}`, data => {
            this.filterIncludeData = data
            this.setFilter()
        })
        eventBus.$on(`set_include_data_${this.page_name}`, data => {
            this.filterIncludeData = data
        })
    },
    beforeDestroy() {
        eventBus.$off(`send_include_fields_${this.page_name}`)
        eventBus.$off(`set_include_data_${this.page_name}`)
    }
}
</script>

<style lang="scss">
.filter_body{
    overflow-y: auto;
    max-height: 50vh;
    
}
.filter_body__vertical{
        max-height: 60vh !important;
 }
.filters_drawer{
    .ant-drawer-header{
        padding: 15px;
    }
    .ant-drawer-body{
        padding: 0px;
        height: calc(100% - 53px);
    }
    .ant-drawer-content,
    .ant-drawer-wrapper-body{
        overflow: hidden;
    }
    .body_wrapper{
        overflow: auto;
        padding: 15px;
        height: calc(100% - 55px);
    }
    .footer_wrapper{
        border-top: 1px solid var(--borderColor);
        background: var(--bgColor);
        position: relative;
        z-index: 5;
        padding: 15px;
        height: 55px;
    }
}
.filter_tag{
    .value_name{
        max-width: 110px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
.ant-popover{
    .filter_header{
        border-bottom: 1px solid var(--borderColor);
    }
}
.filter_item{
    &:not(:last-child){
        margin-bottom: 15px;
    }
}
.filter_pop_wrapper{
    .filter_clear{
        top: 5px;
        right: 0;
        z-index: 5;
        height: 31px;
        opacity: 0.6;
        &:hover{
            opacity: 1;
        }
    }
}
.filter_input{
    .filter_tag,
    .ant-tag{
        height: 100%;
    }
    .anticon-loading{
        color: var(--loadingColor);
    }
    .search_sp{
        opacity: 0.5;
        font-weight: 300;
        margin-right: 4px;
    }
    .main_search{
        .ant-input-affix-wrapper{
            .ant-input{
                padding-right: 20px;
            }
        }
        input{
            border: 0px;
            padding-left: 0px;
            &::placeholder{
                font-weight: 300;
                font-size: 15px;
                color: #a3a3a3;
            }
            &:hover,
            &:focus{
                outline: none;
                box-shadow: initial;
                border-color: initial;
            }
        }
        .ant-input-suffix{
            right: 3px;
            opacity: 0.6;
            margin-top: 1px;
            i{
                display: flex;
                align-items: center;
            }
        }
    }
}
.filter_drawer{
    .ant-drawer-wrapper-body{
        overflow: hidden;
    }
    .ant-drawer-body{
        padding: 0px;
        height: calc(100% - 55px);
    }
    .drawer_body{
        &.hide_search{
            height: calc(100% - 50px);
        }
        &:not(.hide_search) {
            height: calc(100% - 92px);
        }
        .drawer_scroll{
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    .drawer_header{
        border-bottom: 1px solid var(--borderColor);
        .ant-input{
            border-radius: 0px;
            height: 42px;
            border: 0px;
            padding-right: 30px;
        }
    }
    .drawer_footer{
        border-top: 1px solid var(--borderColor);
        height: 50px;
        background: var(--bgColor);
        padding: 4px 11px;
    }
}
</style>

<style scoped lang="scss">
.filter_pop_wrapper{
    max-width: 1200px;
    min-width: 600px;
    width: 100%;
    @media (max-width: 1356px) {
        min-width: 500px;
    }
    @media (max-width: 1056px) {
        min-width: 320px;
    }
}
.filter_active{
    .filter_input{
        padding-right: 31px;
    }
}
.filter_input{
    padding: 6px 10px 6px 11px;
    font-size: 16px;
    color: var(--textColor);
    line-height: 1.5;
    background-color: var(--bgColor);
    background-image: none;
    border: 1px solid var(--borderColor);
    border-radius: var(--borderRadius);
    height: 32px;
    &.large{
        height: 40px;
    }
    &.small{
        height: 24px;
    }
    .search_input{
        outline: none;
        height: 100%;
        width: 100%;
        padding-left: 8px;
    }
}
</style>
